import StaffLoginComp from "./StaffLoginComp";

function StaffLoginContainer(){
    return(
        <div className={"row mt-5 justify-content-center text-center"}>
            <div className={"card col-3"}>
                <img src={"../logo_test.png"} className={"card-img-top"} alt={"logo"}/>
                <h3 className={"card-title text-primary"}>Business Team!</h3>
                <div className={"card-body "}>
                    <StaffLoginComp/>
                </div>
            </div>
        </div>
    )
}
export default StaffLoginContainer;
