import {Routes, Route} from "react-router-dom";
import Login from "./components/Login";
import TokenContext from "./contexs/TokenContext";
import {useState} from "react";
import Register from "./components/Register";
import Home from "./Bundles/Home";
import FrontPage from "./Bundles/FrontPage";
import Error from "./components/Error";
import PendingAuthorization from "./components/PendingAuthorization";
import StaffLoginContainer from "./staff/StaffLoginContainer";
import Dashboard from "./staff/Dashboard";
import RegistrationRejected from "./components/RegistrationRejected";

function App() {
    const [session, setSession] = useState({token:'', username:''})
  return (
      <TokenContext.Provider value={[session, setSession]}>
          <div>
              <Routes>
                  <Route path="/login" element={<Login/>}/>
                  <Route path="/register" element={<Register/>}/>
                  <Route path="/home" element={<Home/>}/>
                  <Route path="/" element={<FrontPage/>}/>
                  <Route path="/error" element={<Error/>}/>
                  <Route path={"/pendingAuthorization"} element={<PendingAuthorization/>}/>
                  <Route path={"/registrationRejected"} element={<RegistrationRejected/>}/>
                  <Route path={"/staff/login"} element={<StaffLoginContainer/>}/>
                  <Route path={"/staff/dashboard"} element={<Dashboard/>}/>
              </Routes>
          </div>
      </TokenContext.Provider>
  );
}

export default App;
