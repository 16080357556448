function StaffNav(props){

    return(
        <div className={"row bg-primary p-2"}>
            <div className={"col-2"}>
                <button className={"btn btn-info wel rounded-pill"}>{props.username}</button>
            </div>
            <div className={"col text-end"}>
                <button className={"btn btn-warning bt"} onClick={()=>{window.location.href="/"}}>
                    Logout
                </button>
            </div>
        </div>
    )
}
export default StaffNav;
