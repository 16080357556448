import {useRef, useState} from "react";

function Register(){
    const usernameRef = useRef();
    const emailRef = useRef();
    const endpointRef = useRef();
    const passwordRef = useRef();
    const cpasswordRef = useRef();
    const [error, setError] = useState('');
    const [feedback, setFeedback] = useState('')
    const [process, setProcess] = useState('');
    async function checkUser(){
        if(!usernameRef.current.value){
            return setError('Please enter a valid username!')
        }
        let res = await fetch(`http://partner.api.safeportal.win/api/partner/check?username=${usernameRef.current.value}`);
        let body = await res.text();
        setError(body);
    }
    function validator(){
        setFeedback('');
        if(!error){
            return setFeedback("Please click on check username and validate first!")
        }
        if(!error.includes('Username Available')){
            return setFeedback("Please enter a valid username!")
        }
        if(!passwordRef.current.value || !cpasswordRef.current.value ){
            return setFeedback("Password cannot be empty!");
        }
        if(passwordRef.current.value.length<8){
            return setFeedback("Password must contain atleast 8 characters!")
        }
        if(passwordRef.current.value !== cpasswordRef.current.value){
            return setFeedback("Both passwords must match!")
        }
        if(!emailRef.current.value.includes('@')){
            return setFeedback("Please enter a valid email address!");
        }
        if(!emailRef.current.value){
            return setFeedback("Email cannot be empty");
        }
        if(!endpointRef.current.value){
            return setFeedback("Endpoint cannot be empty!")
        }
        if(!endpointRef.current.value.includes('.') || endpointRef.current.value.length<5){
            return setFeedback("Please enter a valid endpoint");
        }
        register();
    }
    async function register(){
        setProcess('Creating Account...')
        const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        const data = {
            'username':username,
            'password':password
        }
        let res = await fetch(`http://partner.api.safeportal.win/register`,{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(data)
        });
        let body = await res.json();
        if(body.username){
         setProcess('Account Creation Successful!');
         attachDetails(username);
        }
        async function attachDetails(username){
            setProcess('Verifying and attaching given endpoint to account');
            const data={
                'email':emailRef.current.value,
                'endPoint':endpointRef.current.value
            }
            let res = await fetch(`http://partner.api.safeportal.win/api/partnerdetails/add?username=${username}`,{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(data)
            });
            let body = await res.text();
            setProcess(body);
            setProcess('Account Registered Successfully!');
        }
    }
    return(
        <div className={"m-2 text-center"}>
            <form>
                <div className="form-floating input-group mb-2">
                    <input type="text" id="username" placeholder="username" className="form-control" ref={usernameRef}/>
                    <button type="button" className="btn btn-outline-success" onClick={checkUser}>check</button>
                    <label htmlFor="username">Enter username:</label>
                </div>
                <div><p className={error.includes('Username Available')?"text-success":"text-danger"}>{error}</p></div>
                <div className={"form-floating mb-2"}>
                    <input type="text" id="email" placeholder="Email" className="form-control" ref={emailRef}/>
                    <label htmlFor={"email"}>Enter email:</label>
                </div>
                <div className={"form-floating mb-2"}>
                    <input type={"text"} placeholder={"endpoint"} id={"endpoint"} className={"form-control"} ref={endpointRef}/>
                    <label htmlFor={"endpoint"}>Enter EndPoint</label>
                </div>
                <div className={"form-floating mb-2"}>
                    <input type={"password"} id={"password"}  className={"form-control"} placeholder={"password"} ref={passwordRef}/>
                    <label htmlFor={"password"}>Enter Password:</label>
                </div>
                <div className={"form-floating mb-2"}>
                    <input type={"password"} id="cpassword" className={"form-control"} placeholder={"Confirm password"} ref={cpasswordRef}/>
                    <label htmlFor="cpassword">Confirm Password:</label>
                </div>
                <div>
                    <button type={"button"} className={"btn btn-primary"} onClick={validator}>Register</button>
                </div>
                <div><p className={"text-danger"}>{feedback}</p><p className={"text-success fw-bold"}>{process}</p></div>
            </form>
        </div>
    )
}
export default Register;
