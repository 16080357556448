import {Component} from "react";
import TokenContext from "../contexs/TokenContext";
import StaffNav from "./StaffNav";
import {Navigate} from "react-router-dom";

class Dashboard extends Component{
    state={
        partners:[],
        isLoaded: false,
        error: false,
    }
    static contextType = TokenContext;
    componentDidMount() {
        this.getPartners();
    }
    async getPartners(){
        let res = await fetch('http://businessteam.api.safeportal.win/api/staff/partners/all',{
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer '+this.context[0].token
            }
        });
        let body = await res.json();
        if(body.status===401){
            return this.setState({error:true});
        }
        this.setState({...this.state, partners:body.reverse(), isLoaded:true});
    }
    async authorize(id){
        let res = await fetch(`http://businessteam.api.safeportal.win/api/staff/authorize?partner_id=${id}`,{
            method:'PUT',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+this.context[0].token
            }
        });
        let body = await res.text();
        if(body==="Partner Authorized Successfully!"){
            this.setState({...this.state, isLoaded:false});
            this.getPartners();
        }
    }
    async reject(id){
        let res = await fetch(`http://businessteam.api.safeportal.win/api/staff/reject?partner_id=${id}`,{
            method:'PUT',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+this.context[0].token
            }
        });
        let body = await res.text();
        if(body==="Partner Registration Rejected Successfully!"){
            this.setState({...this.state, isLoaded:false});
            this.getPartners();
        }
    }
    render() {
        let authorized = this.state.partners.filter(i=> i.authorized==="Authorized")
        let unauthorized = this.state.partners.filter(i=>i.authorized==null)
        let rejected = this.state.partners.filter(i=>i.authorized==="Rejected")
        return(
            <div>
            <StaffNav username={this.context[0].username}/>
                {this.state.error?<Navigate to="/error" replace={true}/>:
                    <div>
                {this.state.isLoaded?
                    <div className={"row"}>
                        <div className={"btn-group"}>
                            <button className="m-5 col-2 btn btn-primary" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#pendingAuth" aria-expanded="false" aria-controls="pendingAuth">
                                Partners pending authorization
                            </button>
                            <button className="m-5 col-2 btn btn-primary" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#authorized" aria-expanded="false" aria-controls="authorized">
                                Authorized partners
                            </button>
                            <button className="m-5 col-2 btn btn-primary" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#rejected" aria-expanded="false" aria-controls="rejected">
                                Rejected partners
                            </button>
                        </div>
                        <div className={"collapse text-center"} id={"pendingAuth"}>
                            <h3>Partners pending authorization</h3>
                            <table className={"table"}>
                            <thead className={"thead"}>
                            <tr>
                                <th>Partner ID</th>
                                <th>Email</th>
                                <th>Username</th>
                                <th>EndPoint</th>
                                <th>Authorize?</th>
                            </tr>
                            </thead>
                            <tbody>
                            {unauthorized.map(i=>{
                            return(
                                <tr>
                                    <td>{i.partnerId}</td>
                                    <td>{i.email}</td>
                                    <td>{i.partner.username}</td>
                                    <td>{i.endPoint}</td>
                                    <td><button className={"btn btn-sm btn-primary rounded-pill"} onClick={()=>{this.authorize(i.partnerId)}}>Authorize</button>
                                        <button className={"btn btn-sm btn-danger rounded-pill"} onClick={()=>{this.reject(i.partnerId)}}>Reject</button>
                                    </td>
                                </tr>
                            )
                            })
                            }
                            </tbody>
                        </table>
                    </div>
                        <div className={"collapse text-center"} id={"authorized"}>
                            <h3>Authorized partners</h3>
                            <table className={"table"}>
                                <thead className={"thead"}>
                                <tr>
                                    <th>Partner ID</th>
                                    <th>Email</th>
                                    <th>Username</th>
                                    <th>EndPoint</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {authorized.map(i=>{
                                    return(
                                        <tr>
                                            <td>{i.partnerId}</td>
                                            <td>{i.email}</td>
                                            <td>{i.partner.username}</td>
                                            <td>{i.endPoint}</td>
                                            <td><span className={"badge rounded-pill bg-success"}>Authorized</span></td>
                                        </tr>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className={"collapse text-center"} id={"rejected"}>
                            <h3>Rejected partners</h3>
                            <table className={"table"}>
                                <thead className={"thead"}>
                                <tr>
                                    <th>Partner ID</th>
                                    <th>Email</th>
                                    <th>Username</th>
                                    <th>EndPoint</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rejected.map(i=>{
                                    return(
                                        <tr>
                                            <td>{i.partnerId}</td>
                                            <td>{i.email}</td>
                                            <td>{i.partner.username}</td>
                                            <td>{i.endPoint}</td>
                                            <td><span className={"badge rounded-pill bg-danger"}>Rejected</span></td>
                                        </tr>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :<div className={"spinner-border text-primary"} />}</div>}
            </div>
        )
    }
}
export default Dashboard;
