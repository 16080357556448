function PartnerMapping(props){
    async function deleteMapping(){
        let res = await fetch(`http://partner.api.safeportal.win/api/mappings/delete?mappings_id=${props.data.mappingsId}`,{
            method:'DELETE',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+props.session.token
            }
        });
        let body = await res.text();
        if(body ==="Mapping successfully deleted!"){
            props.fn();
        }
    }
    return(
        <div className={"card col-4 p-1"}>
            <h4 className={"card-title"}>Saved Mapping</h4>
            <div className={"card-body"}>
                <ul className="list-group list-group-flush">
                    {props.data.name!==null?
                        <li className="list-group-item">Name    ➜   {props.data.name}</li>:''}
                    {props.data.pan!==null?
                        <li className="list-group-item">Pan     ➜   {props.data.pan}</li>:''}
                    {props.data.contactNumber!==null?
                        <li className="list-group-item">contactNumber   ➜   {props.data.contactNumber}</li>:''}
                    {props.data.address!==null?
                        <li className="list-group-item">address     ➜   {props.data.address}</li>:''}
                    {props.data.accountNumber!==null?
                        <li className="list-group-item">accountNumber   ➜   {props.data.accountNumber}</li>:''}
                </ul>
            </div>
            <div className={"card-footer"}>
                <button className={"btn btn-danger"} onClick={deleteMapping}>Delete Mapping</button>
            </div>
        </div>
    )
}
export default PartnerMapping;
