import Field from "./Field";

function BankFields(props){

    return(
        <div>
            <Field name="name" value={props.data.name}/>
            <Field name="pan" value={props.data.pan}/>
            <Field name="contactNumber" value={props.data.contactNumber}/>
            <Field name="address" value={props.data.address}/>
            <Field name="accountNumber" value={props.data.accountNumber}/>
        </div>

    )
}
export default BankFields;
