import {useDrag} from "react-dnd";
import {useState} from "react";
function Field({name, value}){
    const [dropped, setDropped] = useState(false);
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'Box',
        item: { name, value },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
               setDropped(true);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }))
    return (
        <div ref={drag} >
            {dropped?"":<input type={"text"} className={" mb-2 form-control"} value={value} name={name} disabled={true}/> }
        </div>
    )
}
export default Field;
