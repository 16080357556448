import {useContext, useRef, useState} from "react";
import TokenContext from "../contexs/TokenContext";
import {Navigate} from "react-router-dom";
function Login(){
    const usernameRef = useRef();
    const passwordRef = useRef();
    const [session, setSession] = useContext(TokenContext);
    const [feedback, setFeedback] = useState(null);
    function validator(){
        if(!usernameRef.current.value){
            return setFeedback("Please enter a valid username!");
        }
        if(!passwordRef.current.value){
            return setFeedback("Password cannot be empty!");
        }
        doLogin();
    }
    async function doLogin(){
        const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        const data = {
            'username':username,
            'password':password
        }
        let res = await fetch('http://partner.api.safeportal.win/authenticate',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        });
        let body = await res.json();
        if(body.token){
            setSession({'token':body.token, 'username':username});
        }else{
            setFeedback("Invalid Credentials!");
        }
    }
    return(
        <div className="col-auto text-center m-2">
            <form>
                <div className="form-floating">
                    <input type="text" placeholder="username" className="form-control mb-2" ref={usernameRef}/>
                    <label htmlFor="username">Enter username:</label>
                </div>
                <div className="form-floating">
                    <input type="password" placeholder="password" className="form-control mb-2" ref={passwordRef}/>
                    <label htmlFor="password">Enter password:</label>
                </div>
                <button type="button" className="btn btn-primary" onClick={validator}>Login</button>
                <p className="text-danger">{session.token? <Navigate to="/home" replace={true}/>:feedback}</p>

            </form>
        </div>
    )
}
export default  Login;
