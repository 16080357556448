import {Component} from "react";
import TokenContext from "../contexs/TokenContext";
import Nav from "../components/Nav";
import {Navigate} from "react-router-dom";
import Bucket from "../components/Bucket";
import BankFields from "../components/BankFields";
import PartnerMapping from "../components/PartnerMapping";

class Home extends Component{
    state={
        customerData:[],
        partnerData:[],
        partnerMappings:[],
        isLoaded:false,
        error:false,
        authorized:'',
        isPartnerDataLoaded:false,
        isPartnerMappingsLoaded: false,
        noMappings:false
    }
    static contextType = TokenContext;
    async componentDidMount(){
       await this.getCustomerData();
       await this.getPartnerDetails();
       await this.getPartnerMappings();
    }
    async getCustomerData(){
        let res = await fetch(`http://partner.api.safeportal.win/api/bank/get`,{
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+this.context[0].token
            }
        });
        let body = await res.json();
        if(body.status===401){
          return this.setState({error:true});
        }
        this.setState({...this.state, customerData:body, isLoaded:true});
    }
    async getPartnerDetails(){
        let res = await fetch(`http://partner.api.safeportal.win/api/partnerdetails/fetch?username=${this.context[0].username}`,{
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+this.context[0].token
            }
        });
        let body = await res.json();
        if(body.authorized!=='Authorized'){
            return this.setState({authorized:body.authorized})
        }else{
            this.setState({...this.state,partnerData:body, isPartnerDataLoaded:true})
        }
    }
    async getPartnerMappings(){
        let res = await fetch(`http://partner.api.safeportal.win/api/mappings/fetch?username=${this.context[0].username}`,{
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+this.context[0].token
            }
        });
        let body = await res.text();
       if(body==="NOT FOUND"){
           return this.setState({...this.state, isPartnerMappingsLoaded:true, noMappings:true});
       }
        this.setState({...this.state, partnerMappings:JSON.parse(body), isPartnerMappingsLoaded:true});
    }
    doRefresh(){
        this.setState({...this.state, isPartnerMappingsLoaded:false});
        this.getPartnerMappings();
    }

    doRefreshAll(){
        this.setState({
            customerData:[],
            partnerData:[],
            partnerMappings:[],
            isLoaded:false,
            error:false,
            authorized:true,
            isPartnerDataLoaded:false,
            isPartnerMappingsLoaded: false,
            noMappings:false});
        this.componentDidMount();
    }
    doRefreshPartnerData(){
        this.setState({...this.state, isPartnerDataLoaded:false});
        this.getPartnerDetails();
    }
    render() {
        return(
            <div className={"row"}>
                <Nav session={this.context[0]} data={this.state.partnerData} fn={()=>{this.doRefreshPartnerData()}}/> {this.state.error?<Navigate to="/error" replace={true}/>:this.state.authorized===null?<Navigate to="/pendingAuthorization" replace={true}/>:this.state.authorized==='Rejected'?<Navigate to="/registrationRejected" replace={true}/>:
                <div className={"row"}><div className={"col m-3 p-2 border border-black"}>
                    {this.state.isLoaded?<BankFields data={this.state.customerData}/>:
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    }
                </div>
                    <div className={"col m-3 p-2 border border-black"}>
                        {this.state.isPartnerDataLoaded?<Bucket endpoint={this.state.partnerData.endPoint} mappingExists={this.state.noMappings} session={this.context[0]} fn={()=>{this.doRefreshAll()}} mapfn={()=>{this.doRefresh()}}/>:<div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                    </div>
                </div>}
                <div className={"row mt-3 text-center justify-content-center"}>
                    {this.state.isPartnerMappingsLoaded?this.state.noMappings?"No Mappings Saved!":<PartnerMapping data={this.state.partnerMappings} session={this.context[0]} fn={()=>{this.doRefresh()}}/>:
                    <div className={"spinner-border text-primary"}/>
                    }
                </div>
            </div>
        )
    }
}
export default Home;
