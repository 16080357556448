import {useState} from "react";
import Login from "../components/Login";
import Register from "../components/Register";
function FrontPage(){
    const [lrToggle, setLRtoggle] = useState(true);
    return(
        <div className={"row mt-5 justify-content-center text-center"}>
        <div className={"card col-3"}>
            <img src={"logo_test.png"} className={"card-img-top"} alt={"logo"}/>
            <div className={"card-body "}>
                <div className={"btn-group"}>
                    <button className={lrToggle?"btn btn-primary":"btn btn-outline-primary"} onClick={()=>{setLRtoggle(!lrToggle)}}>Login</button>
                    <button className={!lrToggle?"btn btn-primary":"btn btn-outline-primary"} onClick={()=>{setLRtoggle(!lrToggle)}}>Register</button>
                </div>
                {lrToggle?<Login/>:<Register/>}
            </div>
        </div>
        </div>
    )
}
export default FrontPage;
