import '../index.css';
import {useRef, useState} from "react";
function Nav(props){
    const [click, setClick] = useState(false)
    const endpointRef = useRef();
    const [feedback, setFeedback] = useState('')
    async function changeEndpoint(){
        if(!endpointRef.current.value || !endpointRef.current.value.includes('.') || !endpointRef.current.value.includes('//') || endpointRef.current.value===props.data.endPoint){
            return setFeedback("Please enter a valid endpoint!")
        }
        let res = await fetch(`http://partner.api.safeportal.win/api/partnerdetails/update/endpoint?partner_id=${props.data.partnerId}&endpoint=${endpointRef.current.value}`,{
            method:'PUT',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+props.session.token
            }
        });
        let body = await res.text();
        setFeedback(body);
        if(body==="Endpoint updated successfully!"){
            props.fn();
        }
    }
    return(
        <div className="row bg-primary p-2">
            <div className={"col"}>
                <button type="button" className="wel btn btn-info rounded-pill" data-bs-toggle="modal" data-bs-target="#settings">
                    {props.session.username}
                </button>
            </div>
            <div className="modal fade" id="settings" tabIndex="-1" aria-labelledby="settings"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="settingsHeader">Settings</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close" onClick={()=>{setClick(false); setFeedback('')}}></button>
                        </div>
                        <div className="modal-body">
                            <button type={"button"} className={click?"btn btn-sm btn-warning":"btn btn-sm btn-primary"} onClick={()=>{setClick(!click)}}>Change Endpoint</button>
                            {click?<div className={"mt-2 text-center"}>
                                <input type={"text"} value={props.data.endPoint} className={"form-control"} placeholder={"current endpoint"} readOnly={true} disabled={true}/>
                                <input type={"text"} className={"mt-2 form-control"} placeholder={"new endpoint"} ref={endpointRef}/>
                                <button className={"mt-2 mb-2 btn btn-danger"} onClick={changeEndpoint}>Change</button>
                                <p className={feedback==="Endpoint updated successfully!"?"text-success fw-400":"text-danger fw-400"}>{feedback}</p>
                            </div>:''}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>{setClick(false); setFeedback('')}}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"col text-end"}>
                <button className={"btn btn-warning bt"} onClick={()=>{window.location.href="/";}}>
                    Logout
                </button>
            </div>
        </div>
    )
}
export default Nav;
