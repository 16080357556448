import {useDrop} from "react-dnd";
import {useRef, useState} from "react";
function Bucket(props) {
    const [data] = useState([])
    const name = useRef();
    const pan = useRef();
    const contactNumber = useRef();
    const address = useRef();
    const accountNumber = useRef();
    const arr = {name, pan,contactNumber,address,accountNumber};
    const [req, setReq] = useState();
    const [pass, setPass] = useState(false);
    const [feedback, setFeedback] = useState('')
    let mapping ={}; //for saving the mapping
    let testMapping={}; //for testing the api
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: 'Box',
        drop: (x,) => {
            data.push(x);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))
    async function testApi(){
        setFeedback();
        testMapping= Object.assign({},...data.map(i=>({[arr[i.name].current.value]:i.value})));
        let res = await fetch(props.endpoint,{
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(testMapping)
        });
        let body = await res.text();
        let status = res.status;
        setReq(body);
        //console.log(res);
        if(status===200 || status===201){
            setPass(true);
        }else{
            setPass(false);
        }

    }
    async function saveMapping(){
        setReq();
        mapping = Object.assign({}, ...data.map(i=>({[i.name]:arr[i.name].current.value})));
        //console.log(mapping);
        if(!props.mappingExists){
            return setFeedback("Delete existing mapping first!")
        }
        let res = await fetch(`http://partner.api.safeportal.win/api/mappings/save?username=${props.session.username}`,{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+props.session.token
            },
            body:JSON.stringify(mapping)
        });
        let body = await res.text();
        setFeedback(body);
        if(body==="Mapping saved!"){
            props.fn();
        }
    }
    const isActive = canDrop && isOver
    return (
        <div ref={drop}>
            <div>{data.length>0?data.map(i=>{
                return(
                    <div className={"input-group mb-2"}>
                        <input className={"form-control"} placeholder={"Enter desired field name"} ref={arr[i.name]}/>
                        <input value={i.value} className={"form-control"} readOnly={true}/>
                    </div>
                );
            }):isActive?'Add':'Drag an input field here'}</div>
            <div className={"mt-5 text-center"}>
                <button type={"button"} className={"btn btn-warning"} onClick={testApi}>Test API</button>
                <button type={"button"} className={pass?"btn btn-success m-2":"btn btn-secondary m-2"} onClick={saveMapping} disabled={!pass}>Save</button>
                {data.length>0? <button type={"button"} className={"btn btn-sm btn-danger"} onClick={()=>{props.fn()}}>Reset</button>:''}
            </div>
            {req? <div className={"mt-3 card text-center"}>
                <h3 className={"card-title"}>Response</h3>
                <div className={"card-body text-info"}><h6 className={pass?"text-success":"text-danger"}>{req}</h6></div>
            </div>:''}
            {feedback?<div className={"mt-3 text-center"}>
                <h3 className={feedback==="Mapping saved!"?"card-title text-success fw-400":"card-title text-danger fw-400"}>{feedback}</h3>
            </div>:''}
        </div>
    )
}
export default Bucket;
